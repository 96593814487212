.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1em;
  text-align: center;
}
.section {
  line-height: 1.4em;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
}
.sectionHeading {
  font-size: 1.5rem;
  margin-bottom: 1em;
}
.section h5 {
  font-size: 1.25rem;
  margin: 3rem 0 1rem;
}
.section p {
  line-height: 1.6em;
  margin-bottom: 1rem;
}
.sincerely {
  margin-top: 4rem;
}

@media (min-width: 60em) {
  .heading {
    font-size: 3rem;
    margin-top: 4rem;
  }
  .sectionHeading {
    font-size: 2rem;
  }
}
